import i18next from 'i18next'
import { reportError } from '@qasa/app'

import { BRAND_CONFIG } from '../config/brand-configurations'
import { mapObject } from '../utils/object'

export const MAX_WATER_COST_PER_TENANT = 40
export const MAX_CLEANING_FEE = 5000

const translate = (value: string, params = {}) => i18next.t(value, params)

export type ValidationFunction<T = LegitimateAny, U = LegitimateAny> = (
  value: T,
  form?: U,
) => ValidationResult

type ValidationResult = { valid: true; message?: string } | { valid: false; message: string }

type BaseValidationConfig = {
  errorMessage?: string
  errorMessageParams?: Record<string, string>
}
type MinValidationConfig = { min: number } & BaseValidationConfig
type MaxValidationConfig = { max: number } & BaseValidationConfig
type PatternValidationConfig = { pattern: RegExp } & BaseValidationConfig
type DateValidationConfig = { dateFormat: string } & BaseValidationConfig

type ValidationConfig =
  | BaseValidationConfig
  | MinValidationConfig
  | MaxValidationConfig
  | PatternValidationConfig
  | DateValidationConfig

type Validation = (config?: ValidationConfig) => ValidationFunction

function formatErrorMessage(errorMessage: string, params: Record<string, string>) {
  return translate(`validation_utils:error_messages.${errorMessage}`, params)
}

function buildErrorMessageParams(config: ValidationConfig) {
  const errorMessageParams = config.errorMessageParams ? mapObject(config.errorMessageParams, translate) : {}

  if ('min' in config) {
    return { ...errorMessageParams, min: config.min.toString() }
  } else if ('max' in config) {
    return { ...errorMessageParams, max: config.max.toString() }
  } else if ('dateFormat' in config) {
    return {
      ...errorMessageParams,
      dateFormat: i18next.t(`validation_utils:date_formats.${config.dateFormat}`),
    }
  }
  return errorMessageParams
}

function buildValidation(
  validate: (value: FixThisTypeLater) => boolean,
  defaultErrorMessage: string,
): Validation {
  return (config = {}) =>
    (value) =>
      validate(value)
        ? { valid: true }
        : {
            valid: false,
            message: formatErrorMessage(
              config.errorMessage ?? defaultErrorMessage,
              buildErrorMessageParams(config),
            ),
          }
}

export const combineValidators = (...validators: ValidationFunction[]) => {
  return (value: FixThisTypeLater, form?: FixThisTypeLater): ValidationResult => {
    for (const validator of validators) {
      const result = validator(value, form)
      if (!result.valid) return result
    }

    return { valid: true }
  }
}

const validateSome = (...validators: ValidationFunction[]) => {
  return (value: FixThisTypeLater, form?: FixThisTypeLater): ValidationResult => {
    const isAnyValid = validators.some((validator) => validator(value, form).valid)

    return isAnyValid ? { valid: true } : { valid: false, message: validators[0](value, form).message! }
  }
}

const validateRequired = (value: FixThisTypeLater) =>
  value === 0 || (typeof value === 'string' ? Boolean(value.trim()) : Boolean(value))
const validateRequiredBoolean = (value: FixThisTypeLater) => value === true || value === false
const validateRequiredAllowNull = (value: FixThisTypeLater) => validateRequired(value) || value === null
const validatePattern =
  ({ pattern, isInverted = false }: { pattern: RegExp; isInverted?: boolean }) =>
  (value: FixThisTypeLater) =>
    isInverted ? !pattern.test(value) : pattern.test(value)
export const validatePatternForPhoneNumber = (pattern: RegExp) => (value: FixThisTypeLater) =>
  pattern.test(value.trim().replace(/(?!^)\+|[^\d+]+/g, '')) // Trim, keep leading +, remove anything that's not a number
const validateMin = (min: number) => (value: FixThisTypeLater) => !isNaN(parseInt(value, 10)) && value >= min
const validateMax = (max: number) => (value: FixThisTypeLater) => !isNaN(parseInt(value, 10)) && value <= max
const validateMinLength = (minLength: number) => (value: FixThisTypeLater) =>
  typeof value === 'string' && value.trim().length >= minLength
const validateMaxLength = (maxLength: number) => (value: FixThisTypeLater) =>
  typeof value === 'string' && value.length <= maxLength

const emailPatternString = '[@]'
const phoneNumberPatternString = '(\\d){9,}'

const urlPatternString =
  '((https|http)\\:\\/\\/)?(?:www\\.)?[\\d\\w]{2,}\\.(com|org|net|edu|gov|mil|int|biz|info|name|pro|coop|museum|aero|jobs|travel|mobi|tel|asia|cat|xxx|tv|io|ly|se|fi|co|uk|de|fr|es|it|nl|eu|co\\.uk)\\/?((\\??[\\dw]+\\/?){1,10}|)?'

const POSSIBLE_PHONE_NUMBER_OR_EMAIL_OR_URL = new RegExp(
  urlPatternString + // Matches any string with a top level domain and protocol
    '|' +
    emailPatternString + // Matches any string with an "@"
    '|' +
    phoneNumberPatternString, // Matches any string with 9 or more digits or dashes
  'i',
)

const POSSIBLE_PHONE_NUMBER_OR_EMAIL = new RegExp(
  emailPatternString + // Matches any string with an "@"
    '|' +
    phoneNumberPatternString, // Matches any string with 9 or more digits or dashes
  'i',
)

// OK to disable this lint rule since it's only run on the client
/* eslint-disable security/detect-unsafe-regex */
export const emailPattern =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
/* eslint-enable security/detect-unsafe-regex */

// TODO: add a pattern that supports other phone number other than swedish numbers
// More or less copied from https://github.com/floere/phony/blob/master/lib/phony/countries.rb#L670 which we use in BE // Anton Lejon 2022-02-23
const finnishPhonePattern =
  /^(0|\+358|00358)?(((753[02][3-9]|7575[3-9]|7598[3-9])\d{3,5})|((73[0-4]\d\d)\d{5})|((202[14-9]|209[0-7])\d{4,6})|((45[45789]\d)\d{2,6})|((10[1-9]|20[13-8]|30[1-9]|73[5-9])\d{5,7})|((71\d)\d{6})|((73[5-9])\d{7})|((43\d|45[0-36])\d{5,7})|(49\d)\d{8}|(([235]9)\d{6,8})|((4[0124678]|50)\d{4,8}))$/
const swedishMobilePhonePattern = /^(([+]46)|(46)|0|)+\s*(7[02369])\s*(\d{3})\s*(\d{2})\s*(\d{2})$/
// NOTE: this was added to allow other phone numbers (our main regex is focused on mobile phone numbers)
const swedishPhonePattern = /^(([+]46)|(46)|0|)\d{7,9}$/
// OK to disable this lint rule since it's only run on the client
// eslint-disable-next-line security/detect-unsafe-regex
const internationalPhonePatternITU = /^\+(?:[0-9] ?){6,14}[0-9]$/
const numberPattern = /^[0-9]+$/
const postalCodePattern = /^\d{3}[\s]?\d{1,2}$/

export const noNumberPattern = /^([^0-9]*)$/

export const required = buildValidation(validateRequired, 'required')
export const requiredAllowNull = buildValidation(validateRequiredAllowNull, 'required')
export const requiredBoolean = buildValidation(validateRequiredBoolean, 'required')
export const onlyNumbers = buildValidation(validatePattern({ pattern: numberPattern }), 'only_numbers')
export const noNumbers = buildValidation(validatePattern({ pattern: noNumberPattern }), 'no_numbers')

export const email = buildValidation(validatePattern({ pattern: emailPattern }), 'email')
export const containsEmailUrlOrPhoneNumberValidator = buildValidation(
  validatePattern({ pattern: POSSIBLE_PHONE_NUMBER_OR_EMAIL_OR_URL, isInverted: true }),
  'disallow_content_with_email_url_or_phone',
)
export const containsEmailOrPhoneNumberValidator = buildValidation(
  validatePattern({ pattern: POSSIBLE_PHONE_NUMBER_OR_EMAIL, isInverted: true }),
  'disallow_content_with_email_or_phone',
)

export const internationalPhoneValidator = buildValidation(
  validatePattern({ pattern: internationalPhonePatternITU }),
  'phone',
)
export const swedishMobilePhoneValidator = buildValidation(
  validatePatternForPhoneNumber(swedishMobilePhonePattern),
  'phone',
)
// NOTE: this validator supports non mobile phone numbers
export const swedishPhoneValidator = buildValidation(
  validatePatternForPhoneNumber(swedishPhonePattern),
  'phone',
)
export const finnishPhoneValidator = buildValidation(
  validatePatternForPhoneNumber(finnishPhonePattern),
  'phone',
)

export const postalCodeValidator = buildValidation(
  validatePattern({ pattern: postalCodePattern }),
  'postal_code',
)

const getPhoneValidatorForCurrentCountry = () => {
  const countryCode = BRAND_CONFIG.countryCode
  switch (countryCode) {
    case 'fi':
      return finnishPhoneValidator()
    case 'se':
      return swedishMobilePhoneValidator()
    default:
      reportError('No phone number validator available for the country code on this platform', {
        countryCode,
      })
      return () => ({ valid: true, message: '' })
  }
}
export const phoneValidator = validateSome(
  internationalPhoneValidator(),
  getPhoneValidatorForCurrentCountry(),
)
// NOTE: covers all possible swedish numbers (mobile and landlines) in international or Swedish format
export const swedishPhoneFullValidator = validateSome(internationalPhoneValidator(), swedishPhoneValidator())

export const pattern = (config: PatternValidationConfig) =>
  buildValidation(validatePattern({ pattern: config.pattern }), 'pattern')(config)

export const max = (config: MaxValidationConfig) =>
  buildValidation(validateMax(config.max), 'max_number')(config)

export const min = (config: MinValidationConfig) =>
  buildValidation(validateMin(config.min), 'min_number')(config)

export const maxLength = (config: MaxValidationConfig) =>
  buildValidation(validateMaxLength(config.max), 'max_length')(config)

export const minLength = (config: MinValidationConfig) =>
  buildValidation(validateMinLength(config.min), 'min_length')(config)

export function isWhitelistedInternalLink(str: string) {
  // OK to disable this lint rule since it's only run on the client
  const internalLinkPattern =
    // eslint-disable-next-line security/detect-unsafe-regex
    /(?:[a-zA-Z0-9-]+\.)?(qasa\.(?:se|fi|fr|com)|bostad\.blocket\.se)(?:\/[^\s?#]*)?(?:\?[^\s#]*)?(?:#[^\s]*)?/gi
  return internalLinkPattern.test(str)
}

export function containsPossiblePhoneNumberOrEmailOrUrl(message: string) {
  const phoneNumberOrEmailOrUrlPattern = /([a-z]{3,}\.)[a-z]{2,}|[@]|(\d|-| ){9,}/gi
  return phoneNumberOrEmailOrUrlPattern.test(message)
}

export function containsProbableUrl(str: string) {
  return new RegExp(urlPatternString, 'gi').test(str)
}

export function containsProbableEmail(str: string) {
  return new RegExp(emailPatternString, 'gi').test(str)
}

export function containsProbablePhoneNumber(str: string) {
  return new RegExp(phoneNumberPatternString, 'gi').test(str)
}
